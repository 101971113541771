// plugins/cookieconsent.client.ts

export default defineNuxtPlugin(() => {
    if (process.client) {
        const script = document.createElement('script');
        script.src = '/js/cookieconsent.js'; // Ensure the path is correct
        script.onload = () => {
            window.CookieConsent.init({
                language: {
                    current: 'en'
                },
                theme: {
                    barColor: '#2C7CBF',
                    barTextColor: '#FFF',
                    barMainButtonColor: '#FFF',
                    barMainButtonTextColor: '#2C7CBF',
                    modalMainButtonColor: '#4285F4',
                    modalMainButtonTextColor: '#FFF',
                    focusColor: 'rgb(853 238 52 / 75%)'
                },
                categories: {
                    necessary: {
                        needed: true,
                        wanted: true,
                        checked: true,
                        language: {
                            locale: {
                                en: {
                                    name: 'Strictly Necessary Cookies',
                                    description: 'These cookies are essential for the website to function properly...'
                                }
                            }
                        }
                    },
                    analytics: {
                        needed: false,
                        wanted: false,
                        checked: false,
                        language: {
                            locale: {
                                en: {
                                    name: 'Analytics',
                                    description: 'These cookies help us analyze how users interact with our site...'
                                }
                            }
                        }
                    },
                    marketing: {
                        needed: false,
                        wanted: false,
                        checked: false,
                        language: {
                            locale: {
                                en: {
                                    name: 'Marketing',
                                    description: 'These cookies are used for marketing and to provide personalized ads...'
                                }
                            }
                        }
                    },
                    various: {
                        needed: false,
                        wanted: false,
                        checked: false,
                        language: {
                            locale: {
                                en: {
                                    name: 'Various Cookies',
                                    description: 'Miscellaneous cookies that may not fit into other categories...'
                                }
                            }
                        }
                    }
                },
                consentModeControls: {
                    ad_storage: 'marketing',
                    ad_user_data: 'marketing',
                    ad_personalization: 'marketing',
                    analytics_storage: 'analytics'
                },
                services: {
                    customService: {
                        category: 'analytics',
                        type: 'dynamic-script', // Can be dynamic-script, script-tag, wrapped, or localcookie
                        search: 'customServiceKeyword', // Adjust as needed
                        language: {
                            locale: {
                                en: {
                                    name: 'Custom Analytics Service'
                                }
                            }
                        }
                    }
                }
            });
        };
        script.onerror = () => {
            console.error('Failed to load the cookie consent script.');
        };
        document.head.appendChild(script);
    }
});
