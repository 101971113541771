import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://43baa0351e579e6ca91836a568238812@o968134.ingest.us.sentry.io/4507806818631680",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // Tracing
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/staging\.forgewerks\.com\//,
    /^https:\/\/forgewerks\.com\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
});
