import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import { Observer } from "gsap/Observer";
import { CustomEase } from "gsap/CustomEase";

export default defineNuxtPlugin(() => {
  gsap.registerPlugin(Observer, ScrollTrigger, CustomEase);

  if (import.meta.client) {
    gsap.registerPlugin(Draggable);
  }

  return {
    provide: {
      gsap,
      Observer,
      ScrollTrigger,
      Draggable,
      CustomEase,
    },
  };
});
