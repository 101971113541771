import { default as _91_46_46_46permalink_93qmBXf7QWHtMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/[...permalink].vue?macro=true";
import { default as _404YstVXzgU4jMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/404.vue?macro=true";
import { default as abouton3DGmj79KMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/about.vue?macro=true";
import { default as contact_45usUa6KqIcwLYMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/contact-us.vue?macro=true";
import { default as _91id_93fHfgDOsA7iMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/our-work/[id].vue?macro=true";
import { default as indexcvXDgd2At4Meta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/our-work/index.vue?macro=true";
import { default as _91_46_46_46id_93XQQ1fY4NHSMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/services/[...id].vue?macro=true";
import { default as indexAvL84jYQuAMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/services/index.vue?macro=true";
import { default as thoughtsZye94eFmaDMeta } from "/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/thoughts.vue?macro=true";
export default [
  {
    name: "permalink",
    path: "/:permalink(.*)*",
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/[...permalink].vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    meta: _404YstVXzgU4jMeta || {},
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "our-work-id",
    path: "/our-work/:id()",
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/our-work/[id].vue").then(m => m.default || m)
  },
  {
    name: "our-work",
    path: "/our-work",
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/our-work/index.vue").then(m => m.default || m)
  },
  {
    name: "services-id",
    path: "/services/:id(.*)*",
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/services/[...id].vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    meta: indexAvL84jYQuAMeta || {},
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "thoughts",
    path: "/thoughts",
    component: () => import("/home/runner/work/forge-website-nuxt/forge-website-nuxt/pages/thoughts.vue").then(m => m.default || m)
  }
]